import Router, { LandingRouter, PostRouter } from "./Router";
// import TagManager from 'react-gtm-module';
// const DATE_START = new Date('2021-08-16 00:00:00');
const DATE_START = new Date(2023, 8, 6, 0, 0, 0);
// const DATE_END = new Date('2021-12-16 00:00:00');
const DATE_END = new Date(2023, 11, 6, 0, 0, 0);

// TagManager.initialize({
// 	gtmId: 'GTM-PQHQZW3',
// 	dataLayerName: 'grandmilioner',
// });

const liveHosts = ["stanigrandmilioner.mk", "www.stanigrandmilioner.mk", "grandmilioner.grandkafa.mk"]

function App() {
  if (
    new Date() < DATE_START &&
    liveHosts.includes(window.location.host)
  ) {
    return <LandingRouter />;
  }
  if (
    new Date() > DATE_END &&
    liveHosts.includes(window.location.host)
  ) {
    return <PostRouter />;
  }
  return <Router />;
}

export default App;
