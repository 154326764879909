import "./Landing.scss";

const Landing = () => {
  return (
    <main id="uskoro">
      <div className="header_uskoro">НАСКОРО</div>
      <div className="nagradeHolder">
        <div className="redBG">ГЛАВНА НАГРАДА ×3</div>
        <img className="glavna" src="/images/milion.png" alt="Glavna nagrada" />
      </div>
      <div className="nagHolder">
        <div className="hh">
          <div className="redBG">НЕДЕЛНИ НАГРАДИ</div>
          <img
            className="nedeljnaSlika"
            src="/images/nedeljna.png"
            alt="Dnevne Nagrade"
          />
          <p>Ваучер за патување</p>
        </div>
        <div className="hh">
          <div className="redBG">ДНЕВНИ НАГРАДИ</div>
          <img
            className="dnevne mobile"
            src="/images/dnevna_mob.png"
            alt="Dnevne Nagrade"
          />
          <img
            className="dnevne desktop"
            src="/images/dnevna_mob.png"
            alt="Dnevne Nagrade"
          />
          <p>5 х GRAND пакет производи</p>
        </div>
      </div>
    </main>
  );
};

export default Landing;
