import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import {
  Home,
  Mehanizam,
  Landing,
  Footer,
  Header,
  Nagrade,
  Kontakt,
  Pravila,
  Dobitnici,
  Galerija,
  Kviz,
} from "./components";

export const LandingRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/">
          <Landing />
        </Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export const PostRouter = () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/galerija">
          <Galerija />
        </Route>
        <Route path="/dobitnici">
          <Dobitnici />
        </Route>
        <Redirect to="/dobitnici" />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/mehanizam">
          <Mehanizam />
        </Route>
        <Route exact path="/nagrade">
          <Nagrade />
        </Route>
        <Route exact path="/dobitnici">
          <Dobitnici />
        </Route>
        <Route exact path="/pravila">
          <Pravila />
        </Route>
        <Route exact path="/galerija">
          <Galerija />
        </Route>
        <Route exact path="/kontakt">
          <Kontakt />
        </Route>
        <Route exact path="/kviz">
          <Kviz />
        </Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
