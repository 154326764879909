import "./Ucestvuj.scss";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";


const Ucestvuj = ({ onFinish }) => {
  const [disabled, setDisabled] = useState(false);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [terms, setTerms] = useState(false);
  const [infobip, setInfobip] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    const status = await axios
      .post(
        "/api/prijava",
        { phone, code, terms, infobip },
        { validateStatus: (status) => true }
      )
      .then((res) => ({ status: res.status, data: res.data }));

    onFinish(status.status, status.data);
    setDisabled(false);
  };

  return (
    <div className="ucestvuj">
      <p>
        Внесете го телефонскиот број и код од внатрешниот дел на пакувањето и
        кликнете на <span className="bold">Пријави се</span>
      </p>
      <form onSubmit={onSubmit}>
        <div>
          <input
            type="text"
            required
            name="phone"
            placeholder="07*******"
            maxLength="9"
            onChange={(e) => {
              setPhone(e.target.value.toUpperCase().replace(/\D/g, ""));
              // if (phonePattern.test(phone)) {
              //   console.log('"true');
              //   e.target.setCustomValidity(" ");
              // } else {
              //   e.target.setCustomValidity("Ова поле е задолжително");
              // }
              e.target.setCustomValidity("");
            }}
            pattern="07[\d]{7,8}"
            value={phone}
            onInvalid={(e) => {
              e.target.setCustomValidity("Ова поле е задолжително");
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
            }}
          />
          <label className="brown">број на мобилниот телефон</label>
        </div>
        <div>
          <input
            type="text"
            required
            name="code"
            placeholder="123456"
            maxLength="6"
            minLength="6"
            pattern={".{6}"}
            onChange={(e) => setCode(e.target.value.toUpperCase())}
            onInvalid={(e) => {
              e.target.setCustomValidity("Ова поле е задолжително");
            }}
            onInput={(e) => {
              e.target.setCustomValidity("");
            }}
            value={code}
          />
          <label className="brown">код од внатрешниот дел на пакувањето</label>
        </div>
        <div className="checkbox">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            required
            onChange={(e) => {
              setTerms(e.target.checked);
              e.target.setCustomValidity("");
            }}
            onInvalid={(e) => e.target.setCustomValidity("Ова поле е задолжително")}

          />
          <label htmlFor="terms">Со оваа пријава потврдувам дека ги прифаќам <Link to="/pravila" className={'link'} target="_blank">Правила за наградна игра</Link></label>
        </div>
        <div className="checkbox">
          <input
            type="checkbox"
            id="infobip"
            name="infobip"
            onChange={(e) => {
              setInfobip(e.target.checked);
            }}
          />
          <label htmlFor="infobip">Се согласувам личните податоци кои ги доставив да се користат или обработуваат во согласност со <a href="https://www.grandkafa.mk/page/pravila-zastite-privatnosti" className="link" target="_blank" rel="noreferrer">правилата за приватност</a>, за испраќање е-пораки поврзани со работата на организаторот, како посебни понуди, нови одлики, рецепти и општи известувања за настани, презентации и други известувања, како и да се користат за анализа и статистичка обработка за подобрување на искуството на корисниците на дигиталните платформи, спроведување истражувања на пазарот и промотивни активности, како и рекламирање.</label>
        </div>
        <button type="submit" disabled={disabled}>
          <img src="/images/prijavi_se.png" alt="Prijavi se" />
        </button>
      </form>
    </div>
  );
};

export default Ucestvuj;