import React, { useState, useEffect } from 'react';
import './Counter.scss';

const DATES = [
    new Date(2023, 7, 31, 14, 30, 0),
    new Date(2023, 9, 6, 13, 0, 0),
    new Date(2023, 10, 6, 13, 0, 0),
    new Date(2023, 11, 6, 13, 0, 0),
];

const zeroPad = (num, places) => String(num).padStart(places, '0');

const Counter = ({ onFinish, on10Minutes, on10Seconds }) => {
    const [distance, setDistance] = useState(1);
    const [timer, setTimer] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [intervalID, setIntervalID] = useState(null);

    useEffect(() => {
        const DATE_END = DATES.find((e) => e.getTime() - Date.now() > 0) || null;
        if (!DATE_END) return;
        const timeLeft = DATE_END.getTime() - new Date().getTime();
        setDistance(timeLeft);

        if (timeLeft <= 600000) {
            if (on10Minutes) {
                on10Minutes();
            }
        }

        if (timeLeft <= 10000) {
            if (on10Seconds) {
                on10Seconds();
            }
        }

        const id = setInterval(() => {
            const timeLeft = DATE_END.getTime() - new Date().getTime();
            setDistance(timeLeft);
        }, 1000);
        setIntervalID(id);

        return () => {
            clearInterval(id);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (distance <= 0) {
            clearInterval(intervalID);
            if (onFinish) {
                onFinish();
            }
            return;
        }

        if (distance > 1 && distance <= 600000) {
            if (on10Minutes) {
                on10Minutes();
            }
        }

        if (distance > 1 && distance <= 10000) {
            if (on10Seconds) {
                on10Seconds();
            }
        }

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimer({
            days: zeroPad(days, 2),
            hours: zeroPad(hours, 2),
            minutes: zeroPad(minutes, 2),
            seconds: zeroPad(seconds, 2),
        });
        // eslint-disable-next-line
    }, [distance, intervalID, onFinish]);

    return (
        <div className="counter">
            <div>
                <div>
                    <h2>{timer.days}</h2>
                </div>
                <p>Ден</p>
            </div>
            <div>
                <div>
                    <h2>{timer.hours}</h2>
                </div>
                <p>Час</p>
            </div>
            <div>
                <div>
                    <h2>{timer.minutes}</h2>
                </div>
                <p>Минути</p>
            </div>
            <div>
                <div>
                    <h2>{timer.seconds}</h2>
                </div>
                <p>Секунди</p>
            </div>
        </div>
    );
};

export default Counter;
