import './Kontakt.scss';

const Kontakt = () => {
	return (
		<main id="kontakt">
			<h1>КОНТАКТ</h1>
			<img src="/images/kontakt.png" alt="Kontakt" />
			<p>Call Center</p>
			<p>0800 200 00</p>
		</main>
	);
};

export default Kontakt;
