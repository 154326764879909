import "./Pravila.scss";

const Pravila = () => {
  return (
    <main id="pravila">
      <div className="text">ПРАВИЛА</div>

      <div className="prav customScroll">
        <div>
          <p>
            Врз основа на член 125,126,127,128 и 129 од Законот за игри на среќа и забавните игри (Сл. Весник на РСМ со сите негови последователни измени и дополненија), како и овластувањето од Актот за основање на друштвото АТЛАНТИЦ ГРАНД ДООЕЛ Скопје, Управителот на ден 28.07.2023 година ги донесе следниве:
          </p>
        </div>
        <div>
          <h2>ПРАВИЛА ЗА НАГРАДНА ИГРА</h2>
          <h1>„СТАНИ ГРАНД МИЛИОНЕР!“</h1>
        </div>
        <div>
          <h1>Член 1</h1>
          <h2>Приредувач на наградната игра</h2>
          <p>
            Приредувач на наградната игра е Друштвото за производство, трговија
            и услуги АТЛАНТИЦ ГРАНД ДООЕЛ увоз – извоз Скопје (скратен назив:
            АТЛАНТИЦ ГРАНД ДООЕЛ Скопје), со седиште на ул. Шар Планина бр. 6,
            1000, со ЕДБ МК4030005554510 и ЕМБС 6003443 (во понатамошниот текст:
            Приредувач на наградната игра).
          </p>
        </div>

        <div>
          <h1>Член 2</h1>
          <h2>
            Производи и услуги заради чие рекламирање се приредува наградната
            игра
          </h2>
          <p>
            Наградната игра се организира со цел рекламирање на производите на
            Приредувачот на наградната игра и тоа:
          </p>
          <ul>
            <li>Гранд Голд 200 г.</li>
            <li>Гранд Стронг 200 г. </li>
            <li>Гранд Арома 200 г. </li>
          </ul>
        </div>

        <div>
          <h1>Член 3</h1>
          <h2>Име, опис и време траење на наградната игра</h2>
          <p>Име на наградната игра е: “СТАНИ ГРАНД МИЛИОНЕР!”</p>
          <p>
            Наградната игра ќе трае од 06.09.2023 година со почеток во 00:01 часот, а завршува на 05.12.2023 година во 23:59 часот. Потрошувачот може да учествува во наградната игра врз основа на купување на било кој од производите наведени во член 2 од овие Правила.
          </p>
          <p>
            Во наградната игра учествуваат исклучиво следните пакувања на Гранд
            Кафе:
          </p>
          <img
            src="/images/pravila_kafe.png"
            style={{ width: 300 }}
            alt="kafe"
          />
          <p>
            Овие пакувања во себе имаат испечатен ЕДИНСТВЕН КОД кој се состои од
            6 алфанумерички карактери на внатрешната страна на фолијата, односно
            во кесичката за кафе (во понатамошниот текст: Код). Секој код е
            единствен и отпречатен само еднаш во некое од пакувањата
          </p>
          <p>
            Секој учесник во наградната игра кој ќе биде добитник на одредена
            награда е должен да го чува кодот од кесичката како доказ за учество
            во наградната игра и тоа се до превземањето на награда, како услов
            за да ја добие наградата.
          </p>
          <p>
            За да учествува во наградната игра “СТАНИ ГРАНД МИЛИОНЕР!” потрошувачот се пријавува преку испраќање Kод и број на мобилен телефон na www.grandkafa.mk или СМС порака со следната содржина: GRAND(празно место)код на број 149491, на начин и под услови опишани во овие Правила.
          </p>
          <p>
            Потрошувачот може да се пријави за учество во наградната игра преку испраќање на податоците по пат на СМС или Viber порака или преку веб страната grandmilioner.grandkafa.mk.
          </p>
          <p>
            Секој исправно примен код, заедно со бројот на телефон, претставува
            единствена пријава во базата преку ВЕБ пријава, односно секоја
            исправно примена СМС пријава со содржина GRAND(празно место)код
            претставува исто така единствена пријава во базата (во понатамошниот
            текст: Пријава).
          </p>
          <p>
            Исправните примени пријави кои ги исполнуваат наведените услови,
            учествуваат во извлекување на дневна, неделна, односно главна
            награда на начин и под услови предвидени со овие Правила.
          </p>
          <p>Нема ограничување во бројот на испратени кодови по учесник.</p>

          <p>1. Пријава по пат на СМС порака:</p>
          <p>
            Пријавата по пат на СМС порака, потрошувачот ја врши преку испраќање
            на кодот од кесичката. Пораката се испраќа на телефонскиот број
            149491 достапен за телефонските оператори А1 и МК Телеком. Пораката
            треба да биде со следната содржина: GRAND(празно место)код (на
            пример: GRAND 12А56U).
          </p>
          <p>
            Кодот може да се испрати и преку Viber каналот на Гранд кафе, на начин што кога ќе се пронајде каналот на Гранд кафе на Viber ќе се заочне комуникација со четбот - chatbot . Праќањето кодови преку Viber е бесплатно.
          </p>
          <p>
            Учесникот кој испратил пријава по пат на СМС порака ќе добие
            повратна порака за статусот на доставената пријава на телефонски
            број од кој ја испратил пораката со некоја од следните содржини:
          </p>
          <ul>
            <li>
              Наградната игра сè уште не започнала! Следете не и учествувајте од 06.09.2023. Ваше Гранд Кафе
            </li>
            <li>
              Честитаме! Освоивте дневна награда! Чувајте го кодот од кесичката
              и очекувајте повик од нашиот центар за повици! Ваше Гранд Кафе
            </li>
            <li>
              Пријавата не е прифатена. Проверете дали правилно сте ја внеле
              пријавата и обидете се повторно или јавете се на 0800 200 00. Ваше
              Гранд Кафе
            </li>
            <li>
              Кодот е веќе искористен. Секој код може да се користи само еднаш
              во текот на наградната игра. За повеќе информации јави се на 0800
              200 00. Ваше Гранд Кафе
            </li>
            <li>
              За жал не освоивте дневна награда, но чувајте го кодот од
              кесичката и чекајте го извлекувањето на неделната и/или главната
              награда! Ваше Гранд Кафе
            </li>
            <li>Наградната игра е завршена на 05.12.2023! Ваше Гранд Кафе</li>
          </ul>
          <p>
            Само учесникот кој ќе испрати исправна пријава ќе добие и повратна
            информација да го сочува кодот од кесичката врз основа на кој го
            пријавил своето учество во наградната игра.
          </p>
          <p>
            Цената по која се наплаќа СМС порака која крајниот корисник т.е. потрошувачот ја испраќа кон мобилните оператори А1 и МК Телеком е 5,90 денари (со вклучен ДДВ), додека Viber пораката е бесплатна. Секој оператор го задржува правото да ја промени цената на СМС пораките согласно својата комерцијална политка. Приредувачот на наградната игра не ја гарантира цената на СМС пораките наведена во овие Правила, ниту сноси одговорност за евентуални промени на цената од страна на мобилните оператори.
          </p>
          <p>
            Корисниците на виртуелни оператори на мобилна телефонија во
            Република Северна Македонија кои не се споени на Премиум СМС сервиси
            и не можат да учествуваат во наградната игра по пат на СМС пораки,
            туку единствено преку веб страната grandmilioner.grandkafa.mk.
          </p>

          <p>2. Пријавата преку интернет</p>
          <p>
            Пријавата преку интeрнет, потрошувачот ја врши преку пополнување на
            интернет образец кој се наоѓа на веб страната
            grandmilioner.grandkafa.mk. Потрошувачот е должен да го пополни
            образецот со следните податоци: Kод (на пример: 12А56U), како и да
            впише број на мобилен телефон. Учесникот во наградната игра е
            одговорен за точноста и вистинитоста на телефонскиот број кој го
            впишува.
          </p>
          <p>
            Учесникот кој доставил пријава преку веб страната ќе добие повратна
            порака за статусот на доставената пријава со некоја од следните
            содржини:
          </p>
          <ul>
            <li>
              Честитаме! Освоивте дневна награда Чувајте го кодот од кесичката и
              очекувајте повик од нашиот центар за повици! Ваше Гранд Кафе
            </li>
            <li>
              Пријавата не е прифатена. Провери дали е внесен добар код и обиди
              се повторно или јави се на 0800 200 00. Ваше Гранд Кафе
            </li>
            <li>
              Кодот е веќе искористен. Секој код може да се користи само еднаш
              во текот на наградната игра. За повеќе информации јави се на 0800
              200 00. Ваше Гранд Кафе
            </li>
            <li>
              За жал не освоивте дневна награда, но чувајте го кодот од
              кесичката и чекајте го извлекувањето на неделната и/или главната
              награда! Ваше Гранд Кафе
            </li>
          </ul>
          <p>
            Само учесникот кој ќе испрати исправна пријава ќе добие и повратна
            информација да го сочува кодот од кесичката на кој се наоѓа кодот
            врз основа на кој го пријавил своето учество во наградната игра.
          </p>
          <p>Пријавата преку интернет е бесплатна.</p>
        </div>

        <div>
          <h1>Член 4</h1>
          <h2>Запознавање на учесниците со правилата на наградната игра</h2>
          <p>
            Официјалните правила на наградната игра по добивањето согласност од
            Министрството за финансии, а пред започнување на наградната игра, ќе
            бидат објавени на веб страната grandmilioner.grandkafa.mk.
          </p>
          <p>
            Покрај објавување на правилата за наградната игра на веб страната
            grandmilioner.grandkafa.mk учесниците во наградната игра ќе бидат
            известени за начинот и условите за учество во наградната игра, како
            и за нејзиното времетраење преку медиуми, средства за јавно
            информирање и промотивни материјали изложени на местата на продажба
            во маркетите во кои се спроведува наградната игра.
          </p>
          <p>
            Сите информации поврзани со наградната игра, потрошувачите и
            учесниците можат да ги добијат на бесплатниот број 0800 200 00.
            Работното време на центарот за повици е од понеделник до петок
            секоја недела од 08:00 до 16:00 часот (освен за време на празници).
          </p>
        </div>

        <div>
          <h1>Член 5</h1>
          <h2>Начин и услови за учество во наградната игра</h2>
          <p>
            Право на учество во наградната игра имаат сите полнолетни физички
            лица со навршени 18 години возраст, со живеалиште во Република
            Северна Македонија (во понатамошниот текст: Учесници), кои ги
            прифаќаат условите на овие правила. Приредувачот на наградната игра
            го задржува правото да ги проверува овие податоци.
          </p>
          <p>
            Во наградната игра забрането е учество на вработените кај Приредувачот на наградната игра (АТЛАНТИЦ ГРАНД ДООЕЛ Скопје), вработените кај овластениот дистрибутер на производите АТЛАНТИЦ ТРАДЕ СКОПЈЕ ДОО Илинден, вработените во Агенцјата ангажирана за спроведување на Наградната игра ПИКСЕЛ, вклучително и членовите на нивните потесни семејства.
          </p>
          <p>
            Со учество во наградната игра, учесниците потврдуваат дека се
            целосно запознаени со одредбите од овие Правила и ја изразуваат
            својата согласност во поглед на истите.
          </p>
          <p>
            Приредувачот на наградната игра го задржува правото да
            дисквалификува учесник за кој постои основано сомневање дека сторил
            некоја неправилност, малверзација или злоупотреба на механизмот на
            учество било тоа да е преку СМС пораки или преку интернет.
          </p>
        </div>

        <div>
          <h1>Член 6</h1>
          <h2>Територија</h2>
          <p>
            Наградната игра се приредува на целата територија на Република
            Северна Македонија.
          </p>
        </div>

        <div>
          <h1>Член 7</h1>
          <h2>Податоци за наградите во наградната игра</h2>
          <p>
            Во рамки на наградната игра “СТАНИ ГРАНД МИЛИОНЕР!” се доделува
            следниот награден фонд на награди т.е. поединечни награди по вид,
            број и вредност (изразени во денари):
          </p>

          <div className="tableHolder">
            <table border="1">
              <thead>
                <tr>
                  <th>Вид на награда</th>
                  <th>Опис на награда</th>
                  <th>Вкупен број на награди</th>
                  <th>Поединечна вредност на награда без ДДВ</th>
                  <th>Поединечна вредност на наградата со ДДВ</th>
                  <th>Вкупна вредност на награди без ДДВ</th>
                  <th>Вкупна вредност на награди со ДДВ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Дневна награда</td>
                  <td>Пакет производи со содржина:</td>
                  <td>455</td>
                  <td>397</td>
                  <td>469</td>
                  <td>204,989</td>
                  <td>241,887</td>
                </tr>
                <tr>
                  <td>Дневна награда</td>
                  <td>1 х Гранд Голд 500гр</td>
                  <td>455</td>
                  <td>130,82</td>
                  <td>154</td>
                  <td>59,523.56</td>
                  <td>70,238</td>
                </tr>
                <tr>
                  <td>Дневна награда</td>
                  <td>1 стаклено ѓезве</td>
                  <td>455</td>
                  <td>213.12</td>
                  <td>251.49</td>
                  <td>96,970.97</td>
                  <td>114,426</td>
                </tr>
                <tr>
                  <td>Дневна награда</td>
                  <td>2 стаклени шољи</td>
                  <td>910</td>
                  <td>53.29</td>
                  <td>62.88</td>
                  <td>48,494.81</td>
                  <td>57,224</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tableHolder">
            <table border="1">
              <thead>
                <tr>
                  <th>Вид на награда</th>
                  <th>Опис на награда</th>
                  <th>Вкупен број на награди</th>
                  <th>Поединечна вредност на награда без ДДВ</th>
                  <th>Поединечна вредност на наградата со ДДВ</th>
                  <th>Вкупна вредност на награди без ДДВ</th>
                  <th>Вкупна вредност на награди со ДДВ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Неделна награда</td>
                  <td>13 х ваучери за патување (Савана)</td>
                  <td>13</td>
                  <td>31,000</td>
                  <td>31,000</td>
                  <td>403,000</td>
                  <td>403,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="tableHolder">
            <table border="1">
              <thead>
                <tr>
                  <th>Вид на награда</th>
                  <th>Опис на награда</th>
                  <th>Вкупен број на награди</th>
                  <th>Поединечна вредност на награда без ДДВ</th>
                  <th>Поединечна вредност на наградата со ДДВ</th>
                  <th>Вкупна вредност на награди без ДДВ</th>
                  <th>Вкупна вредност на награди со ДДВ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Главна награда</td>
                  <td>3 х 1 милион денари</td>
                  <td>3</td>
                  <td>1,000,000</td>
                  <td>1,000,000</td>
                  <td>3,000,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            Вкупниот награден фонд изнесува 3.644.887,00 денари со вклучен ДДВ.
          </p>
          <p>
            Освоените награди, добитниците не можат да ги заменат за друг вид
            награда или услуга.
          </p>
        </div>

        <div>
          <h1>Член 8</h1>
          <h2>Извлекување на добитниците на награди</h2>
          <p>
            Доставените пријави по пат на СМС или преку интернет се собираат,
            обработуват и чуваат во единствена база за пријави.
          </p>
          <p>
            Извлекувањето на наградите ќе се врши на ул. Антон Панов бр. 1/3-18,1000 Скопје.
          </p>
          <p>
            Добитниците на неделните и главните награди ќе се извлекуваат
            автоматски со помош на компјутерска програма, по методот на случаен
            избор од базата на сите исправни пријави кои ги исполнуваат условите
            дефинирани со овие правила.
          </p>
          <p>
            Извлекувањето на добитниците на дневните награди ќе се врши на принципот на механизам на инстант добивки секој ден. Извлекувањето на неделните награди ќе се врши еднаш неделно, секој понеделник во 12:00 часот, со тоа што првото извлекување е на 13.097.2023 гогина, а последното на 06.12.2023 година. Извлекувањето на добитниците на главните награди ќе се врши еднаш во месецот, односно три пати во текот на траењето на наградната игра.
          </p>
          <p>1. Извлекување на добитници на дневни награди </p>
          <p>
            Дневните награди се извлекуваат во текот на секој ден за цело врметраење на наградната игра. Првиот ден на извлекување на дневните награди е 067.09.2023 г., а последен ден е 06.12.2023 г. Компјутерски код/алгоритам, секој ден од 00:00-23:59 по случаен избор одредува по еден момент во рамки на 5 интервали за доделување на една од дневни награди. Учесникот кој прв ќе испрати исправен Код после тој случајно одреден момент освојува награда. Последниот момент во текот на секој ден кога наградата може да биде доделена е 23:59 часот, односно последен момент во текот на траењето на наградната игра кога наградата може да се додели т.е. да биде извлечена е 06.12.2023 година во 23:59.
          </p>
          <p>
            Извлекувањето на добитниците на дневните награди ќе се врши по
            принципот на механизам за инстант добика така што секој учесник во
            наградната игра кој ќе испрати исправна пријава т.е. код по пат на
            СМС/Viber или преку интернет веднаш по праќањето на пријавата добива
            повратна информација дали е добитник на дневна награда или не. При
            извлекувањето на добитниците за дневните награди не се извлекуваат
            резервни добитници, но доколку се утврди дека добитникот на дневна
            не ги исполнува условите предвидени со овој Правилник, во тој случај
            наградата се доделува на учесникот во наградната игра кој прв
            испратил пријава веднаш после иницијалниот добитник на дневната
            награда. Доколку пак и пријавата на учесникот во наградната игра кој
            испратил пријава веднаш после иницијалниот добитник на дневната
            награда не ги исполнува условите предвидени во овие правила, односно
            не е исправна, Приредувачот на наградната игра нема обврска да ја
            додели таквата дневна награда.
          </p>
          <p>
            Пријавата која во извлекувањето на добитниците на дневните награди
            ќе биде извлечена како добитна не учествува понатаму во
            извлекувањето на дневни, како ни во извлекувањето на неделните и
            главните награди. Пријавите кои во извлекувањето на добитниците на
            дневните награди не се извлечени како добитни, учествуваат во
            извлекувањето на неделната или главната награда (подетално опишано
            во точка 2 од овој член).
          </p>
          <p>
            Еден телефонски број може да биде извлечен како добитен за дневна
            награда најмногу еднаш во текот на еден циклус т.е. во текот на еден
            месец или најмногу 3 пати во текот на времетраење на наградната
            игра. Телефонскиот број од кој е пријавена добитна пријава за дневна
            награда понатаму може да учествува во наградната игра во извлекување
            за неделна или главна награда единствено со нова Пријава.
          </p>
          <p>
            Доколку во еден ден, некоја од дневните награди не биде доделена
            поради тоа што не е пратена ниедна валидна пријава во тој период,
            таа награда се пренесува во следниот ден, односно во следниот
            период, па во тој нареден период ќе биде извлечен и добитник на
            дневна награда и за претходниот период.
          </p>
          <p>
            Штом на горенаведениот начин се утврди првиот учесник кој испратил
            исправен Код, на тоа лице ќе му биде доделена првата по ред
            недоделената награда. На истиот принцип на сите следни учесници кои
            испратиле исправен Код им се доделуваат награди по истиот редослед
            по кој ги одредил алгоритамот, се додека не се поделат сите
            недоделени награди. После ова наградите продолжуваат да се делат по
            редовна динамика како што е предвидено со овој Правилник.
          </p>
          <p>
            Извлечените телефонски броеви (со прикриени три цифри од
            телефонскиот број) ќе бидат објавени на официјалната веб страница
            grandmilioner.grandkafa.mk најдоцна наредниот ден до 16:00 часот.
          </p>
          <p>2. Извлекување на добитници на неделни награди </p>
          <p>
            Во периодот на траење на наградната игра, ќе бидат организирани 13  извлекувања на неделната награда. Извлекуваето на неделната награда ќе се одвива секој Среда  во 12:00 часот, почнувајќи од 13.09.2023 година и тоа:
          </p>
          <ul>
            <li>
              на 13.09.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 06.09.2023 од 00:01 до 12.09.2023 г. до 23:59 часот
            </li>
            <li>
              на 20.09.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 13.09.2023 од 00:01 до 19.09.2023 г. до 23:59 часот
            </li>
            <li>
              на 27.09.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 20.09.2023 од 00:01 до 26.09.2023 г. до 23:59 часот
            </li>
            <li>
              на 04.10.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 27.09.2023 од 00:01 до 03.10.2023 г. до 23:59 часот
            </li>
            <li>
              на 11.10.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 04.10.2023 од 00:01 до 10.10.2023 г. до 23:59 часот
            </li>
            <li>
              на 18.10.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 11.10.2023 од 00:01 до 17.10.2023 г. до 23:59 часот
            </li>
            <li>
              на 25.10.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 18.10.2023 од 00:01 до 24.10.2023 г. до 23:59 часот
            </li>
            <li>
              на 01.11.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 25.10.2023 од 00:01 до 31.10.2023 г. до 23:59 часот
            </li>
            <li>
              на 08.11.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 01.11.2023 од 00:01 до 07.11.2023 г. до 23:59 часот
            </li>
            <li>
              на 15.11.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 08.11.2023 од 00:01 до 14.11.2023 г. до 23:59 часот
            </li>
            <li>
              на 22.11.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 15.11.2023 од 00:01 до 21.11.2023 г. до 23:59 часот
            </li>
            <li>
              на 29.11.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 22.11.2023 од 00:01 до 28.11.2023 г. до 23:59 часот
            </li>
            <li>
              на 06.12.2023 г. во 12:00 часот за сите пријави кои се доставени (пријавени) во периодот од 29.11.2023 од 00:01 до 05.12.2023 г. до 23:59 часот
            </li>
          </ul>
          <p>
            Во извлекувањето на неделните награди учествуваат единствено
            пријавите кои не биле добитни во извлекувањето на дневните награди и
            тоа за оние денови т.е. период за кој се организира неделното
            извлекување. Една пријава може да учествува само во едно извлекување
            на неделна награда (и тоа за период за кој е поднесена пријава по
            пат на СМС порака или преку веб страна).{" "}
          </p>

          <p>
            Еден телефонки број може да биде извлечен како добитен за неделна
            награда најмногу еднаш во текот на времетраење на наградната игра.
            Телефонскиот број од кој е пријавена добитна пријава за неделна
            награда понатаму може да учествува во наградната игра во извлекување
            за дневна и главна награда единствено со нова пријава.{" "}
          </p>

          <p>
            Извлечените телефонски броеви (со прикриени три цифри од
            телефонскиот број) ќе бидат објавени на официјалната веб страница
            grandmilioner.grandkafa.mk наредниот ден, најдоцна до 16:00 часот.{" "}
          </p>

          <p>
            Покрај добитникот на неделната награда, дополнително, се извлекуваат
            уште двајца резервни добитници во случај да се утврди дека
            добитникот на неделната награда не ги исполнува условите предвидени
            во овој правилник, односно доставил неисправна пријава или не го
            сочувал кодот од кесичката. Доколку добитникот на неделната награда
            не одговори на повиците на телефонскиот број кој го впишал во
            пријавата и во наредните три работни дена од денот на извлекувањето
            (најмалку повикани по два пати во денот) или пак се утврди дека
            извлечената пријавата за неделна награда не ги исполнува условите од
            овие Правила, наградата ќе се додели на првиот резервен добитник.
            Доколку пак пријавата и на првиот резервен добитник на неделна
            награда не ги исполнува условите предвидени во овие правила и не е
            исправна, Приредувачот на наградната игра ќе ја додели неделната
            награда на вториот резервен добитник. Доколку пак пријавата и на
            вториот резервен добитник не ги исполнува условите предвидени во
            овие правила и не е исправна, Приредувачот на наградната игра нема
            обврска да ја додели таквата неделна награда.
          </p>

          <p>На добитникот на неделната награда му се врачува ваучер, а потоа со туристичката агенција ги договара сите детали и времето на патувањето. Рокот на важност на ваучерите ќе биде 1 (една) година од датумот на доделување/издавање. Добитниците ќе можат ваучерите да ги користат за услуги во вредност на ваучерот или услуги како за себе, така и за други лица и во поголема вредост со доплата на разликата над фактурираната вредност на ваучерот. Доколку добитникот не е во можност да го искористи ваучерот, ќе може да го пренесе на друго лице.</p>

          <p>
            Услуги кои можат добитиниците да ги користат се следните:
          </p>
          <ul>
            <li>Пакет аранжмани за индивидуалци и групи, кои се организираат во текот на целата година во зависност на сезоните, како кружни тури или патувања за одмор, </li>
            <li>Услуги на сместување достапни во системите за резервации (резервации во објекти за сместување низ целиот свет),</li>
            <li>Услуги на превоз достапни во системите за резервации (авионски билети, билети во автобуски превоз, железнички преоз, изнајмување на возила итн.), </li>
            <li>Услуги на екскурзии, водичи, тури, влезници и други активности во дестинациите кои се посетуваат, </li>
            <li>Обезбедување на информации за визниот режим за соодветна дестинација (и посредување во добивање на визи, доколку е можно посредувањето), </li>
            <li>Услуги на советник за патување за реализирање на одредено патување според желбите на клиентот</li>
          </ul>
          <p>3. Извлекување на добитници на главните награди </p>
          <p>
            Извлекувањето на добитниците за главните награди ќе се спроведува во
            живо и преносот ќе се одвива на Facebook. Добитникот кој ќе биде
            извлечен за време на извлекувањето во живо се повикува на
            телефонскиот број кој го впишал во пријавата. Дополнително, се
            извлекуваат уште тројца резервни добитници за главните награди во
            случај да се утврди дека добитникот на главната награда не ги
            исполнува условите предвидени во овој правилник, дносно доставил
            неисправна пријава или не го сочувал кодот од кесичката. Доколку
            добитникот на главната награда не одговори на повиците на
            телефонскиот број кој го впишал во пријавата и во наредните три
            работни дена од денот на извлекувањето (најмалку повикани по два
            пати во денот) или пак се утврди дека извлечената пријавата за
            главна награда не ги исполнува условите од овие Правила, наградата
            ќе се додели на првиот резервен добитник. Доколку пак пријавата и на
            првиот резервен добитник на главната награда не ги исполнува
            условите предвидени во овие правила и не е исправна, Приредувачот на
            наградната игра ќе ја додели главната награда на вториот резервен
            добитник. Доколку пак пријавата и на вториот резервен добитник на
            главната награда не ги исполнува условите предвидени во овие правила
            и не е исправна, Приредувачот на наградната игра ќе ја додели
            главната награда на третиот резервен добитник. Доколку пак и
            пријавата и на третиот резервен добитник на главната награда не ги
            исполнува условите предвидени во овие правила и не е исправна,
            Приредувачот на наградната игра нема обврска да ја додели таквата
            главна награда.
          </p>
          <p>Ќе бидат организирани 3 извлекувања на главни награди и тоа:</p>
          <ol>
            <li>
              За првата главна награда извлекувањето ќе се спроведе на 06.10.2023г. во 13:00 часот. Во извлекувањето за првата главна награда учествуваат сите исправни недобитни пријави кои се испратени во периодот од 06.09.2023 г. од 00:01 до 05.10.2023 до 23:59 часот.
            </li>
            <li>
              За втората главна награда извлекувањето ќе се спроведе на 06.11.2023 г. во 13:00 часот. Во извлекувањето за втората главна награда учествуваат сите исправни недобитни пријави кои се испратени во периодот од 06.10.2023 г. од 00:01 до 05.11.2023 до 23:59 часот.
            </li>
            <li>
              За третата главна награда извлекувањето ќе се спроведе на 06.12.2023 г. во 13:00 часот. Во извлекувањето на третата главна награда учествуваат сите исправни недобитни пријави кои се испратени во периодот од 06.11.2023 г. од 00:01 до 05.12.2023 до 23:59 часот.
            </li>
          </ol>
          <p>
            Пријавите кои биле извлечени како добитни на некоја од дневните или
            неделни награди во еден циклус не учествуваат во извлекувањето на
            главната награда за тој циклус.
          </p>
          <p>
            Еден телефонски број може да биде извлечен како добитен за главна
            награда најмногу еднаш во текот на времетраење на наградната игра.
          </p>
          <p>
            Извлечените телефонски броеви (со прикриени три цифри од
            телефонскиот број) ќе бидат објавени на официјалната веб страница
            grandmilioner.grandkafa.mk најдоцна наредниот ден до 16:00 часот.
          </p>
        </div>

        <div>
          <h1>Член 9</h1>
          <h2>
            Доделување на наградите и контрола на приредувањето на наградната
            игра
          </h2>
          <p>
            Во рок не подолг од 5 работни дена од денот на извлекувањето,
            контакт центарот ги контактира добитниците на дневните награди на
            телефонскиот број од кој е пратена СМС порака или е впишан во
            пријавата преку веб страна, врши идентификација и ги договара
            деталите за превземање на наградата од страна на добитниците.
          </p>
          <p>
            Добитник на наградата е лицето кое ќе одговори на повикот на
            телефонскиот број кој е извлечен како добитен (без оглед дали е
            сопственик на телефонскиот број) и ќе остави лични податоци на
            операторот од контакт центарот и тоа: име и презиме, адреса на
            живеење наведена во документот за лична идентификација кој ќе го
            приложи при подигањето на наградата (улица, број, место), адреса на
            која треба да се достави наградата (улица, број, место) доколку
            истата се разликува од онаа наведена во документот за лична
            идентификација, град и општина.
          </p>
          <p>
            На добитниците на неделните и главни награди, иринаградите ќе им бидат доделени по нивнa идентификација и оставање лични податоци (и
            трансакциска сметка за главните награди) и приложувањето на кодот од
            кесичката во време кое ќе го договори со Приредувачот на наградната
            игра во седиштето на Приредувачот на наградната игра (ул. Шар
            Планина бр. 6, 1000 Скопје). Доколку добитникот или од него
            овластено лице не дојде во седиштето на Приредувачот на наградната
            игра во договорениот термин и не се идентификува и достави
            потребните документи и податоци, Приредувачот на наградната игра ќе
            достави известување на адреса на добитникот оставена во центарот за
            повици за нов термин во кој добитникот или овластено лице ќе можат
            да дојдат во седиштето на Приредувачот за идентификација и оставање
            податоци за исплата/доделување на наградата. Доколку и во овој
            термин добитникот или овластено лице не се пријават, добитникот го
            губи правото на остварување на наградата, а приредувачот на
            наградната игра ќе му ја додели наградата на резервниот добитник
            како што е реулирано со овие правила. Неделните награди ќе бидат
            доделени, а паричните награди ќе бидат исплатени на приложена
            трансакциска сметка на добитниците, во рок од 30 дена од денот на
            објавување на добитниците или подоцна во случај на причини за
            спреченост на страна на добитникот (но не подоцна од законски
            предвидениот рок од 60 дена од денот на објавување на добитниците).
          </p>
          <p>
            Во случај на исплата на парична награда, добитниците кои во моментот
            на контактирање од страна на центарот за повици немаат трансакциска
            сметка, должни се да отворат трансакциска сметка во банка по свој
            избор и лично да се пријават кај Приредувачот на наградната игра за
            исплата на паричната награда. Во спротивно, Приредувачот на
            наградната игра нема обврска да изврши исплата на наградата, ниту
            пак ќе се овозможи друг начин на исплата или замена на паричната
            награда и ваквата награда. Во ваков случај, Приредувачот на
            наградната игра ќе му ја додели наградата на резервниот добитник
            како што е реулирано со овие правила.
          </p>
          <p>
            Добитниците на награди (како парична, така и стокова) се должни да
            го чуваат кодот од кесичката врз основа на кој го пријавиле
            учеството во наградната игра, кој задолжително го приложуваат при
            идентификација на добитникот и подигнување на наградите.
          </p>
          <p>
            Добитникот кој нема да го приложи кодот од кесичката во моментот на
            подигање на наградата или при идентификација за исплата/доделување
            на неделната или главнатачна награда, нема да може да го оствари
            правото на подигнување на наградата, па наградрадата ќе му се додели
            на резервниот добитник (за неделните и главните награди), односно на
            учесникот во наградната игра кој прв испратил пријава веднаш после
            иницијалниот добитник на соодветната награда, како што е предвидено
            во овие Правила.
          </p>
          <p>
            Добитникот е должен задолжително при превземањето на наградата да
            приложи документ за идентификација. Во моментот на превземање на
            наградата се утврдува идентитетот на добитникот преку увид во
            документ за лична идентификација од кој се земаат податоците за име
            и презиме, како и адреса на живеење.
          </p>
          <p>
            Податоци од лична карта, вклучително и ЕМБГ ќе се земе од добитник
            на неделна и главна награда заради идентификација и даночни цели
            т.е. пријава и плаќање на персоналниот данок на доход.
          </p>
          <p>
            Дневните награди ќе бидат испорачани на добитниците на адресата која
            ја оставиле во центарот за повици (нивна домашна адреса или некоја
            друга која добитникот ја оставил и назначил дека на таа адреса треба
            да се изврши доставата на наградата). Доколку добитникот не биде
            најден на соодветната адреса во време кое е договорено, се врши втор
            обид за достава на наградата. Крајниот рок за доставување и
            преземање на наградите до адреса на добитникот е 15 денa од денот на
            извлекувањето на соодветната награда, освен ако со овие правила не е
            пинаку предвидено.
          </p>
          <p>
            При превземањето на наградите добитниците се должни да се
            идентификуваат со документ за лична идентификација, да го предадат
            кодот од кесичката со кој се пријавиле за учество во наградната
            игра, како и да потпишат изјава и/или записник за
            примопредавање/преземање на наградата.
          </p>
          <p>
            Во случај кога добитникот од било кој причина не е во состојба да ја
            подигне наградата лично, истата може да биде предадена на трето лице
            врз основа на заверено полномошно кај надлежен нотар потпишанано од
            страна на добитникот.
          </p>
          <p>
            Лицето кое е одговорно за предавање на стоковата награда/исплата на
            паричната награда пред примопредавањето/исплата на наградата ќе
            утврди дали податоците за лицето кое ја презема наградата
            соодветствуваат на оние оставени во контакт центарот. Дополнително
            се прави увид во кодот од кесичката. Кодот од кесичката се предава
            на лицето одговорно за примопредавање на наградата/исплата на
            паричната награда.
          </p>
          <p>
            Доколку се исполнети условите за примопредавање на наградата се
            потпишува изјава/записник за примопредавање на наградата кој треба,
            меѓудругото, да содржи опис на производот, датум на примопредавање,
            податоци за лицето кое ја презема наградата (име, презиме, адреса и
            единствен матичен број само за добитниците на неделни и главни
            награди) и потпис на добитникот (лицето кое ја презема наградата).
          </p>
          <p>
            Крајниот рок за доставување и преземање на дневните награди до
            адреса на добитникот е 15 енa од денот на извлекувањето на
            соодветната награда. Во случај на 2 (два) неуспешни обиди за достава
            на наградата, добитникот може на свој трошок наградата да ја подигне
            во дополнителен рок од 15 дена од едиштето на Приредувачот на
            наградната игра (ул. Шар Планина бр. 6, 1000 Скопје), по претходно
            договарање термин и детали за преземањето преку центарот за повици.
          </p>
          <p>
            Приредувачот на наградната игра не ги покрива трошоците за превоз
            или патување ниту било каков евентуален друг трошок на добитникот во
            врска со превземање, остварување или исплата на наградата.
          </p>
          <p>
            Од моментот на преземање, односно исплата на наградите (вклучително
            и исплата на персоналниот данок за наградите за кои постои обврска
            за плаќање персонален данок), престануваат сите обврски на
            Приредувачот на наградната игра кон добитниците на награди.
          </p>
          <p>
            Приредувачот на наградната игра нема да изврши доделување на било
            која награда (како парична, така и стокова) по истек на рокот од 60
            дена, сметано од денот на извлекување на добитниците на наградите.
          </p>
          <p>
            Сите барања за доделување на награди кои Приредувачот на наградната
            игра ќе ги прими по истекот на роковите за остварување на поединечни
            права предвидени со овие Правила нема да се разгледуваат, ниту врз
            основа на тие барања учесникот ќе оствари било какви права кон
            Приредувачот на наградната игра.
          </p>
          <p>
            Доколку добитникот не се јави на повикот на приредувачот на
            наградната игра за подигање на наградата или по негова вина не ја
            подигне наградата во предвидените рокови, понатамошните обврски на
            Приредувачот на наградната игра кон добитникот престануваат.
          </p>
          <p>
            Контрола на спроведувањето на наградната игра и определување на
            добивките и добитниците и веродостојноста на кодот од кесичките ќе
            врши лице овластено од страна на Приредувачот на наградната игра (за
            што ќе се донесе посебен акт - решение).
          </p>
        </div>

        <div>
          <h1>Член 10</h1>
          <h2>Даночни обврски на добитниците на наградите</h2>
          <p>
            Приредувачот на наградната игра е одговорен за сите даноци,
            надоместоци и одговорности поврзани со наградите, вклучувајќи го и
            персоналниот данок на доход.
          </p>
          <p>
            Приредувачот на наградната игра е должен на добитниците на награди
            за кои се пресметува персонален данок да им издаде потврда за платен
            персонален данок.
          </p>
        </div>

        <div>
          <h1>Член 11</h1>
          <h2>Прекинување на наградна игра</h2>
          <p>
            Наградната игра може да се прекине во случај на виша сила, како и во
            случај да настапат околности за кои Приредувачот на наградната игра
            не е одговорен, односно ако постојат околности кои Приредувачот не
            можел да ги спречи, отстрани или избегне.
          </p>
          <p>
            За прекин на наградната игра, причините и датумот на прекин на
            наградната игра, Приредувачот на наградната игра писмено ќе го
            извести Министерството за финансии.
          </p>
          <p>
            За прекин на наградната игра Приредувачот ќе ги извести учесниците
            во наградната игра на веб страната grandmilioner.grandkafa.mk или
            објава преку јавен медиум.
          </p>
        </div>

        <div>
          <h1>Член 12</h1>
          <p>
            Учесникот кој учествува во наградната игра ги прифаќа правата и
            обврските кои произлегуваат од Правилата на наградната игра.
          </p>
          <p>
            Правилата на наградната игра ги пропишува приредувачот на наградната
            игра, а истите се применуваат по добиената согласност која ја дава
            Министерството за финансии на Република Северна Македонија.
          </p>
          <p>
            Правилата на наградната игра, со цел запознавање на потенцијалните
            учесници на истата, ќе бидат објавени и јавно достапни на
            grandmilioner.grandkafa.mk на македонски јазик, пред почетокот на
            наградната игра, a по добивањето на согласност од страна на
            Министерството за финансии. За начинот на учество во наградната игра
            ќе можат да се информираат и преку реклами на средствата за јавно
            информирање и рекламен материјал на продажните места.
          </p>
        </div>

        <div>
          <h1>Член 13</h1>
          <h2>Право на приговор</h2>
          <p>
            Незадоволниот учесник има право да поднесе писмен приговор до
            Приредувачот на наградната игра, во рок од 5 (пет) работни дена од
            денот на секое објавување на добитниците на веб страната
            grandmilioner.grandkafa.mk и тоа на адреса на Приредувачот на
            наградната игра (ул. Шар Планина бр. 6, 1000 Скопје).
          </p>
          <p>
            За решавање на евентуални приговори на добитници на награда во однос
            на достава/исплата на наградите, добитниците имаат право да поднесат
            приговор до приредувачот на нагрдната игра во рок од 5 дена од денот
            на објавување на добитниците.
          </p>
          <p>
            Приредувачот е должен писмено да одговори на приговорот во рок од 5
            (пет) работни дена од приемот на истиот.
          </p>
        </div>

        <div>
          <h1>Член 14</h1>
          <h2>
            Согласност и собирање, обработување и зачувување на лични податоци
          </h2>
          <p>
            Во согласност со Правилата, односно со испраќање на СМС порака на
            бројот 149491 или внесување на соодветна содржина со податоци на
            grandmilioner.grandkafa.mk за пријавување на учество во наградната
            игра, учесникот се согласува неговите лични податоци да бидат
            обработувани од страна на Приредувачот (мобилен или фиксен телефон)
            со цел за учество во наградната игра.
          </p>
          <p>
            Во согласност со Правилата, доколку учесникот биде известен дека е
            добитник на некоја од наградите предмет на оваа наградна игра,
            истиот се согласува неговите лични податоци да бидат обработувани од
            страна на Приредувачот за остварување на легитимни цели односно
            законски обврски (име и презиме, адреса на живеење со улица и број,
            место и општина, место на престој како и ЕМБГ(ЕМБГ само за неделните
            и главните награди)) за доделување односно испорака на добиената
            награда.
          </p>
          <p>
            Ова вклучува собирање, евидентирање, организирање, чување,
            приспособување или промена, повлекување, консултирање, употреба,
            откривање преку пренесување, објавување или на друг начин правење
            достапни, изедначување, комбинирање, блокирање, бришење или
            уништување на личните податоци. Обработката и преносот на податоци
            од страна на Приредувачот на наградната игра е со цел да се оствари
            промоција на производите и услугите кои се предмет на оваа активност
            односно полесно да се реализира и обработи секој учесник
            индивидуално во наградната игра во согласност со овие Правила.
          </p>
          <p>
            Со учество во наградната игра, учесникот дава согласност неговите
            лични податоци да можат да се обработуваат и користат за маркетинг
            цели (директен маркетинг) односно промоција на производите и
            услугите предмет на оваа наградна игра.
          </p>
          <p>
            Со учество во наградната игра, учесникот може да даде и согласност
            да биде фотографиран и снимен, а неговите фотографии и снимен
            материјал да се обработуваат за целите на наградната игра и истите
            да се објавуваат на медиуми (пишани, електронски, телевизија и др.),
            но и за цели на вршење директен маркетинг на производите и услугите
            на сите поврзани друштва со Приредувачот.
          </p>
          <p>
            Личните податоци ќе бидат споделени со трета страна единствено за
            предмет и цели компатибилни на наградната игра односно со страна чие
            што учество во наградната игра е неизбежно (на пример, компанија
            која ги испорачува наградите).
          </p>
          <p>
            Врз основа на оваа согласност, а доколку не постои друг законски
            основ, податоците ќе се чуваат согласно Законот за заштита на лични
            податоци и другите позитивни прописи во РМ во рок кој е неопходен за
            исполнување на сите цели и обврски од оваа наградна игра но не
            подого од 30 дена по истек на последниот ден за испорака на
            наградите до добитниците.
          </p>
          <p>
            Приредувачот се обврзува да после исполнувањето на целите на
            наградната игра, односно завршување на сите активности предвидени со
            овие Правила, согласно прописите за заштита на личните податоци во
            Република Северна Македонија, пристапи кон уништување на личните
            податоци за кои нема законски или друг основ за натамошна обработка
            и чување.
          </p>
          <p>
            Секој учесник има право во секое време, бесплатно и со доставување
            информација до центарот за повици на Приредувачот на наградната игра
            да ја повлече согласноста за обработка на неговите лични податоци за
            цел на директен маркетинг, наведувајќи ги притоа неговите лични
            податоци неопходни за идентификација на субјектот. Доколку лицето не
            ги наведе соодветните лични податоци од претходниот став, односно
            истото не може да се идентификува, неговото барање нема да се
            спроведе.
          </p>
          <p>
            Учесниците имаат право во секој момент да побараат нивните лични
            податоци да бидат избришани или да побараат корекција на неточни
            податоци, како и да побараат информација од Приредувачот на
            наградната игра во врска со обработката на нивните лични податоци.
          </p>
        </div>

        <div>
          <h1>Член 15</h1>
          <h2>Одговорност</h2>
          <p>
            Потенцијалниот учесник во оваа наградна игра разбира и се согласува
            дека учествува во наградната игра на свој сопствен ризик.
          </p>
          <p>
            Приредувачот на наградната игра не одговара за евентуалните штети
            причинети во текот или во врска со учеството во наградната игра.
          </p>
          <p>
            Приредувачот не одговара во случај кога добиена награда не може да
            биде дадена или искористена поради неможност потенцијалниот добитник
            или негов претставник да се јави и да ја добие наградата во
            определените рокови или поради други технички или правни пречки
            поврзани со конкретниот добитник или негов претставник и поради
            околности предизвикани од виша сила, нормативни или законски
            ограничувања или други околности.
          </p>
          <p>
            Приредувачот на наградната игра не одговара во случај на спор околу
            кодот од кесичката, туку го прифаќа како добитник лицето кое се
            пријавило за учество во наградната игра и ги оставил своите податоци
            и истото го приложи кодот од кесичката во моментот на идентификација
            и предавање на наградата.
          </p>
        </div>

        <div>
          <h1>Член 16</h1>
          <h2>Завршни одредби</h2>
          <p>
            Учесникот кој учествува во наградната игра ги прифаќа правата и
            обврските кои произлегуваат од Правилата на наградната игра.
            Правилата на наградната игра ги пропишува Приредувачот на наградната
            игра, а истите се применуваат по добиената согласност која ја дава
            Министерството за финансии, пред започнувањето на наградната игра.
            Правилата на наградната игра, со цел запознавање на потенцијалните
            учесници на истата, ќе бидат објавени јавно на веб страната
            grandmilioner.grandkafa.mk, пред почетокот на наградната игра, по
            добивањето на согласност од страна на Министерството за финансии.
          </p>
        </div>

        <div>
          <h1>Член 17</h1>
          <h2>Решавање на евентуални спорови</h2>
          <p>
            Секое несогласување помеѓу Приредувачот на наградната игра и
            учесниците во наградната игра ќе се решава спогодбено, а во случај
            на спор, за решавање на истиот надлежен ќе биде судот во Скопје.
          </p>
        </div>
      </div>
    </main>
  );
};

export default Pravila;
