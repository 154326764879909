import './Footer.scss';

const Footer = () => {
	return (
		<footer>
			<div>
				<img src="/images/atl_logo_red.png" alt="Atlantic Logo" />
				<div className="desktop"></div>
				<p>Наградната игра трае од <span className='bold'>06.09.2023.</span> до <span className='bold'>05.12.2023.</span></p>
			</div>
		</footer>
	);
};

export default Footer;
