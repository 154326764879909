import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.scss";

// const kvizStart = new Date(2022, 11, 16, 10, 0, 0);

const Header = () => {
  const navbarWidthBreakpoint = 992;

  const location = useLocation();

  const size = useWindowSize();
  const [hide, setHidden] = useState(false);
  const [logo, setLogo] = useState("");
  const handleHamClick = () => {
    setHidden(!hide);
  };

  const closeNavBar = () => {
    if (size.width < navbarWidthBreakpoint) setHidden(true);
  };

  useEffect(() => {
    if (size.width > navbarWidthBreakpoint) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }, [size]);

  useEffect(() => {
    if (location.pathname === "/") {
      setLogo("grand_");
    } else {
      setLogo("");
    }
  }, [location]);

  return (
    <header>
      <div className="holder">
        <Link to="/">
          <img
            className={`logo ${logo}`}
            src={`/images/${logo}logo.png`}
            alt="Logo"
          />
        </Link>
        <div className="ham">
          <button onClick={() => handleHamClick()}>
            <img src="/images/hamburger.png" alt="Ham Menu" />
          </button>
        </div>
        <div id="nav" className={hide ? "hide" : ""}>
          <nav>
            <ul>
              <li>
                <Link
                  to="/"
                  className={`${location.pathname === "/" ? "active" : ""}`}
                  onClick={() => closeNavBar()}
                >
                  УЧЕСТВУВАЈ
                </Link>
              </li>
              <li>
                <Link
                  to="/mehanizam"
                  className={`${
                    location.pathname === "/mehanizam" ? "active" : ""
                  }`}
                  onClick={() => closeNavBar()}
                >
                  МЕХАНИЗАМ
                </Link>
              </li>
              <li>
                <Link
                  to="/nagrade"
                  className={`${
                    location.pathname === "/nagrade" ? "active" : ""
                  }`}
                  onClick={() => closeNavBar()}
                >
                  НАГРАДИ
                </Link>
              </li>
              <li>
                <Link
                  to="/dobitnici"
                  className={`${
                    location.pathname === "/dobitnici" ? "active" : ""
                  }`}
                  onClick={() => closeNavBar()}
                >
                  ДОБИТНИЦИ
                </Link>
              </li>
              <li>
                <Link
                  to="/pravila"
                  className={`${
                    location.pathname === "/pravila" ? "active" : ""
                  }`}
                  onClick={() => closeNavBar()}
                >
                  ПРАВИЛА
                </Link>
              </li>
              <li>
                <Link
                  to="/galerija"
                  className={`${
                    location.pathname === "/galerija" ? "active" : ""
                  }`}
                  onClick={() => closeNavBar()}
                >
                  ГАЛЕРИЈА
                </Link>
              </li>
              <li>
                <Link
                  to="/kontakt"
                  className={`${
                    location.pathname === "/kontakt" ? "active" : ""
                  }`}
                  onClick={() => closeNavBar()}
                >
                  КОНТАКТ
                </Link>
              </li>
              {/* {new Date() > kvizStart && (
                <li>
                  <Link
                    to="/kviz"
                    className={`${
                      location.pathname === "/kviz" ? "active" : ""
                    }`}
                    onClick={() => closeNavBar()}
                  >
                    КВИЗ
                  </Link>
                </li>
              )} */}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
