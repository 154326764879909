import axios from 'axios';
import { useState, useEffect } from 'react';
import './Galerija.scss';
import Modal from 'react-modal';

const customStyles = {
    overlay: {
        zIndex: 20,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 21,
        padding: 0,
        border: 'none',
    },
};

const Galerija = () => {
    const [galerija, setGalerija] = useState([]);
    const [data, setData] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        console.log('ee');
        (async () => {
            const data = await axios.get('/api/galerija').then((res) => res.data);
            setGalerija(data);
            console.log({ data });
        })();
    }, []);

    return (
        <main id="galerija">
            <div className="holder">
                {galerija.map((e, i) => (
                    <div
                        key={i}
                        onClick={() => {
                            setData(e);
                            setShowModal(true);
                        }}
                    >
                        <img src={e.link} alt="Galerija" />
                        <p>{e.text}</p>
                    </div>
                ))}
            </div>
            <Modal
                ariaHideApp={false}
                isOpen={showModal}
                style={customStyles}
                onRequestClose={() => setShowModal(false)}
                closeTimeoutMS={200}
            >
                <div className="modalView">
                    <img src={data.link} alt="Galerija" />
                    <p>{data.text}</p>
                </div>
            </Modal>
        </main>
    );
};

export default Galerija;
