import "./Mehanizam.scss";

const Mehanizam = () => {
  return (
    <main id="mehanizam">
      <div>
        <div className="h">
          <div className="num">1</div>
          <p>
            Купи Grand Gold, Aroma или Strong од 200гр и пронајди го кодот во
            внатрешниот дел од пакувањето.
          </p>
          <img src="/images/meh_1.png" alt="Mehanizam 1" />
        </div>
      </div>
      <div>
        <div className="h">
          <div className="num">2</div>
          <p>
            Прати го кодот на www.grandkafa.mk, прати СМС:<br /> GRAND(празно
            место)код на 149491 или преку Вибер каналот Grand kafa.
          </p>
          <img src="/images/meh_2.png" alt="Mehanizam 2" />
        </div>
      </div>
      <div>
        <div className="h">
          <div className="num">3</div>
          <p>
            Стани Гранд милионер секој месец или освој некоја од вредните дневни
            и неделни награди.
          </p>
          <img src="/images/meh_3.png" alt="Mehanizam 3" />
        </div>
      </div>
    </main>
  );
};

export default Mehanizam;
