import { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./Kviz.scss";
import { useForm } from "react-hook-form";

const isValidEmail = (email) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

const isValidNumber = (number) => /07[\d]{7,8}/.test(number);

const Kviz = () => {
  const [scene, setScene] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [selectedAnswer, setSelectedAnswer] = useState(-1);
  const [timer, setTimer] = useState(10);
  const [quizResult, setQuizResult] = useState(null);
  const [dataSuccess, setDataSuccess] = useState(false);
  const [dataError, setDataError] = useState(false);
  const quizData = useRef({
    sessionId: null,
    data: [],
    quizStartedAt: null,
  });
  const intervalId = useRef(null);
  //   const quizStartedAt = useRef(null);
  const form = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (scene === 1) {
      startQuiz();
    }
  }, [scene]);

  useEffect(() => {
    if (
      scene === 1 &&
      questions.length > 0 &&
      currentQuestion >= questions.length
    ) {
      clearInterval(intervalId.current);
      submitAnswers();
    }
  }, [currentQuestion, questions, scene]);

  useEffect(() => {
    if (timer < 0) {
      quizData.current.data[currentQuestion].answer = selectedAnswer;
      setSelectedAnswer(-1);
      //   setAnswers([...answers, selectedAnswer]);
      setCurrentQuestion(currentQuestion + 1);
      clearInterval(intervalId.current);
      setTimer(10);
      startTimer();
    }
  }, [timer]);

  const startQuiz = async () => {
    setCurrentQuestion(0);
    let data = null;
    try {
      data = await axios.get("/api/quiz").then((res) => res.data);
    } catch (err) {
      return window.location.reload();
    }
    setQuestions(data.questions);
    quizData.current.sessionId = data.session;
    quizData.current.quizStartedAt = Date.now();
    data.questions.map((e) => {
      quizData.current.data.push({ question: e.id, answer: 0 });
    });
    startTimer();
  };

  const handleAnswerClick = (e) => {
    setSelectedAnswer(e);
  };

  const handleAnswerSubmit = () => {
    if (selectedAnswer === -1) return;
    // setAnswers([...answers, selectedAnswer]);
    quizData.current.data[currentQuestion].answer = selectedAnswer;
    setSelectedAnswer(-1);
    setCurrentQuestion(currentQuestion + 1);
    clearInterval(intervalId.current);
    setTimer(10);
    startTimer();
  };

  const startTimer = () => {
    intervalId.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  };

  const startOver = () => {
    window.location.reload();
  };

  const submitAnswers = async () => {
    setScene(-1);

    let time = Date.now() - quizData.current.quizStartedAt;
    if (time > 50000) {
      time = 50000;
    }
    time = time + "";
    time = time.split("");
    time.reverse();
    time.splice(3, 0, ".");
    time.reverse();
    time.join("");

    let res = null;
    try {
      res = await axios
        .post("/api/quiz/score", quizData.current)
        .then((res) => res.data);
    } catch (err) {
      return window.location.reload();
    }

    setQuizResult({
      correctAnswers: res.correctAnswers,
      time,
    });
    setScene(2);
  };

  const submitData = async (data) => {
    setDataError(false);
    setDataSuccess(false);
    try {
      const res = await axios
        .post("/api/quiz/data", {
          sessionId: quizData.current.sessionId,
          ...data,
        })
        .then((res) => res.status);

      setDataSuccess(true);
      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    } catch (err) {
      setDataError(true);
    }
  };

  if (scene === 0)
    return (
      <main id="kviz">
        <img className="kv" src="/images/kviz_kv.png" alt="Grand Kviz" />
        <a className="linkHolder" href="/kako_se_igra.pdf" target="_blank">
          <img
            className="igrajKviz"
            src="/images/kviz_kako.png"
            alt="IGRAJ KVIZ"
          />
        </a>
        <img
          className="igrajKviz"
          onClick={() => setScene(1)}
          src="/images/igraj_kviz.png"
          alt="IGRAJ KVIZ"
        />
      </main>
    );

  if (scene === 1 && questions[currentQuestion]) {
    return (
      <main id="kviz">
        <div className="holder">
          <div className="innerHolder">
            <div className="kvizHeader">
              <img src="/images/kviz_hl.png" alt="hl" />
              <div className="counter">
                <div>
                  <div>
                    <h2>{timer}</h2>
                  </div>
                  <p>Секунди</p>
                </div>
              </div>
            </div>
            <div className="currentQuestion">
              <p>{currentQuestion + 1} ОД 5</p>
            </div>
            <div className="pitanje">
              <p>{questions[currentQuestion].text}</p>
            </div>
            <div className="odgovori">
              {questions[currentQuestion].answers.map((e, i) => (
                <div
                  key={i}
                  onClick={() => handleAnswerClick(i)}
                  className={[selectedAnswer === i ? "active" : ""]}
                >
                  <p>{e}</p>
                </div>
              ))}
            </div>
            <div className="potvrdi">
              <img
                onClick={() => handleAnswerSubmit()}
                src="/images/kviz_potvrdi.png"
                alt="potvrdi"
              />
            </div>
          </div>
        </div>
      </main>
    );
  }

  if (scene === 2 && quizResult)
    return (
      <main id="kviz">
        <div className="holder">
          <div className="innerHolder">
            <div>
              <img className="hlVeci" src="/images/kviz_hl.png" alt="hl" />
            </div>
            <div className="h1Holder">
              <h1>ЧЕСТИТАМЕ!</h1>
            </div>
            <div className="rez">
              <p>ПОЕНИ:</p>
              <p>{quizResult.correctAnswers}</p>
              <p></p>
            </div>
            <div className="rez">
              <p>ВРЕМЕ:</p>
              <p>{quizResult.time}</p>
              <p>СЕК</p>
            </div>
            <div className="buttonHolder">
              <img
                onClick={() => setScene(3)}
                src="/images/kviz_prijavi.png"
                alt="prijavi"
              />
            </div>
            <div className="buttonHolder">
              <img
                onClick={() => startOver()}
                src="/images/kviz_ponovo.png"
                alt="prijavi"
              />
            </div>
          </div>
        </div>
      </main>
    );

  if (scene === 3)
    return (
      <main id="kviz">
        <div className="holder formHolder">
          <div className="innerHolder">
            <form onSubmit={form.handleSubmit(submitData)}>
              <div className="input">
                <input
                  className={form.formState.errors.firstName ? "error" : ""}
                  {...form.register("firstName", { required: true })}
                />
                {/* {form.formState.errors.firstName && (
                  <label className="error">Ова поле е задолжително</label>
                )} */}
                <label>ИМЕ</label>
              </div>
              <div className="input">
                <input
                  className={form.formState.errors.lastName ? "error" : ""}
                  {...form.register("lastName", { required: true })}
                />
                {/* {form.formState.errors.lastName && (
                  <label className="error">Ова поле е задолжително</label>
                )} */}
                <label>ПРЕЗИМЕ</label>
              </div>
              <div className="input">
                <input
                  className={form.formState.errors.email ? "error" : ""}
                  placeholder="xxx@xxx.com"
                  {...form.register("email", {
                    required: true,
                    validate: isValidEmail,
                  })}
                />
                {/* {form.formState.errors.email && (
                  <label className="error">Ова поле е задолжително</label>
                )} */}
                <label>Е-ПОШТА</label>
              </div>
              <div className="input">
                <input
                  className={form.formState.errors.phone ? "error" : ""}
                  maxLength={10}
                  placeholder="07xxxxxxx"
                  {...form.register("phone", {
                    required: true,
                    maxLength: 10,
                    validate: isValidNumber,
                  })}
                />
                {/* {form.formState.errors.phone && (
                  <label className="error">Ова поле е задолжително</label>
                )} */}
                <label>БРОЈ НА МОБИЛЕН ТЕЛЕФОН</label>
              </div>
              <div className="input">
                <input
                  className={form.formState.errors.city ? "error" : ""}
                  {...form.register("city", { required: true })}
                />
                {/* {form.formState.errors.city && (
                  <label className="error">Ова поле е задолжително</label>
                )} */}
                <label>ГРАД</label>
              </div>
              <div className="input">
                <input
                  className={form.formState.errors.address ? "error" : ""}
                  {...form.register("address", { required: true })}
                />
                {/* {form.formState.errors.address && (
                  <label className="error">Ова поле е задолжително</label>
                )} */}
                <label>АДРЕСА</label>
              </div>
              <div className="check">
                <input
                  id="check1"
                  type="checkbox"
                  {...form.register("check1", { required: true })}
                  className={form.formState.errors.check1 ? "error" : ""}
                />
                <label htmlFor="check1">
                  Со оваа пријава потврдувам дека ги прифаќам{" "}
                  <a href="/pravila" target="_blank">
                    Правилата на наградниот квиз
                  </a>{" "}
                  и{" "}
                  <a href="/kako_se_igra.pdf" target="_blank">
                    Правилата за приватност
                  </a>{" "}
                  за користење на моите лични податоци во согласност со
                  одредбите од дадените Правила, вклучувајќи го и користењето на
                  мојата е-пошта i телефонски број за промотивните потреби на
                  друштвото Атлантик Гранд ДОО Скопје.
                </label>
              </div>
              <div className="check">
                <input
                  id="check2"
                  type="checkbox"
                  {...form.register("check2", { required: true })}
                  className={form.formState.errors.check2 ? "error" : ""}
                />
                <label htmlFor="check2">
                  Потврдувам дека внесените податоци се точни.
                </label>
              </div>
              {dataError && (
                <div className="message">
                  <p className="error">
                    Секој корисник може да поднесе резултат само еднаш во текот
                    на траењето на натпреварот.
                  </p>
                </div>
              )}
              {!dataSuccess && (
                <div className="input">
                  <button
                    className="submitButton"
                    disabled={form.formState.isSubmitting}
                    type="submit"
                  >
                    <img src="/images/prijavi_se.png" alt="prijavi se" />
                  </button>
                </div>
              )}
              {dataSuccess && (
                <div className="message">
                  <p className="success">Успешна пријава!</p>
                </div>
              )}
            </form>
          </div>
        </div>
      </main>
    );

  return null;
};

export default Kviz;
