import { useState } from "react";
import "./Home.scss";
import Counter from "../Counter/Counter";
import Modal from "react-modal";
import Ucestvuj from "../Ucestvuj/Ucestvuj";
// import TagManager from 'react-gtm-module';

const customStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 21,
    padding: 0,
    border: "none",
    borderRadius: "30px",
  },
};

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [message, setMessage] = useState("");
  return (
    <main className="homeMain">

      <div className="hero">
        <div className="spark spark1" />
        <div className="spark spark2" />
        <img className="tabla" src="/images/hero.png" alt="Hero" />
      </div>

      <div className="holder">
        <div>
          <img className="kafe" src="/images/kafe.png" alt="Kafe" />
        </div>
        <div>
          <div>
            <img
              onClick={() => setShowModal(true)}
              className="ucestvujDugme"
              src="/images/ucestvuvaj.png"
              alt="Ucestvuj"
            />
          </div>
          <p className="timerP">
            ВРЕМЕ ДО ПРОГЛАСУВАЊЕ НА <br className="mobile" /> ГЛАВНИОТ МЕСЕЧЕН ДОБИТНИК
          </p>
          <Counter />
          <p className="timerExp">
            *Следи го одбројувањето и види го списокот на награди и добитници.
          </p>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={showModal}
        style={customStyles}
        onRequestClose={() => setShowModal(false)}
        closeTimeoutMS={200}
      >
        <Ucestvuj
          onFinish={(status, data) => {
            setShowModal(false);
            let message = "";
            let gtmevent = "";
            switch (status) {
              case 200:
                message = `Честитаме! Освоивте дневна награда. Чувајте го кодот од кесичката и очекувајте повик од нашиот центар за повици! Ваше Гранд Кафе`;
                gtmevent = "Winner";
                break;
              case 202:
                message =
                  "За жал не освоивте дневна награда, но чувајте го кодот од кесичката и чекајте го извлекувањето на неделната и/или главната награда! Ваше Гранд Кафе";
                gtmevent = "Not winner";
                break;
              case 409:
                message =
                  "Кодот е веќе искористен. Секој код може да се користи само еднаш во текот на наградната игра. За повеќе информации јави се на 0800 200 00. Ваше Гранд Кафе";
                gtmevent = "Used";
                break;
              default:
                message =
                  "Пријавата не е прифатена. Провери дали е внесен добар код и обиди се повторно или јави се на 0800 200 00. Ваше Гранд Кафе";
                gtmevent = "Code wrong";
                break;
            }

            setMessage(message);
            setShowModal2(true);
            window.dataLayer.push({
              event: "GTMEvent",
              eventCategory: "Forms",
              eventAction: gtmevent,
              eventLabel: "grandmilionerMK23",
            });
          }}
        />
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={showModal2}
        className="ModalMessage"
        style={customStyles}
        onRequestClose={() => setShowModal2(false)}
        closeTimeoutMS={200}
      >
        <img className="modalLogo" src="/images/logo.png" alt="Logo" />
        <div>{message}</div>
      </Modal>
    </main>
  );
};

export default Home;
