import { useEffect, useState } from "react";
import "./Dobitnici.scss";
import Confetti from "react-dom-confetti";
import Counter from "../Counter/Counter";
import axios from "axios";

const confettiConfig = {
  angle: 90,
  spread: 236,
  startVelocity: 45,
  elementCount: 200,
  dragFriction: 0.05,
  duration: 4300,
  stagger: 3,
  width: "15px",
  height: "15px",
  perspective: "400px",
  colors: ["#fedc6f", "#dfa93b", "#b5761c", "#9d5a15"],
};

const Dobitnici = () => {
  const [showConffeti, setShowConfetti] = useState(false);
  const [broj, setBroj] = useState("+389xxxxxxx");
  const [opacity, setOpacity] = useState(0);
  const [dnevni, setDnevni] = useState([]);
  const [nedeljni, setNedeljni] = useState([]);
  const [glavni, setGlavni] = useState([]);
  const [on10Min, setOn10Min] = useState(false);
  const [on10Sec, setOn10Sec] = useState(false);
  const [showKraj, setShowKraj] = useState(false);

  useEffect(() => {
    (async () => {
      const glavni = await axios.get("/api/glavni").then((res) => res.data);
      setGlavni(glavni);

      const dobitnici = await axios
        .get("/api/dobitnici")
        .then((res) => res.data);
      setDnevni(dobitnici);

      const nedeljni = await axios.get("/api/nedeljni").then((res) => res.data);
      setNedeljni(nedeljni);
    })();

    // if (new Date() > new Date(2021, 11, 16, 13)) {
    //     setShowKraj(true);
    // }
  }, []);

  useEffect(() => {
    if (showConffeti) {
      setTimeout(() => {
        setOpacity(1);
      }, 2000);

      setTimeout(() => {
        document.body.style.overflow = "";
      }, 4500);
    }
  }, [showConffeti]);

  useEffect(() => {
    if (on10Sec) {
      (async () => {
        try {
          const res = await axios.get("/api/glavni").then((res) => res.data);
          setBroj(res[res.length - 1].telefon);
        } catch (err) {
          console.log("ERROR NA 10 SEC", err);
          setBroj("+389xxxxxxx");
        }
      })();
    }
  }, [on10Sec]);

  return (
    <main id="dobitnici">
      {showKraj && (
        <div className="kraj red">
          <h1>Наградната игра е завршена!</h1>
          <h1>Честитаме на добитниците и Ви благодариме на учеството!</h1>
        </div>
      )}
      {!showKraj && (
        <Counter
          onFinish={() => {
            document.body.style.overflow = "hidden";
            setShowConfetti(true);
          }}
          on10Minutes={() => {
            if (!on10Min) {
              setOn10Min(true);
            }
          }}
          on10Seconds={() => {
            if (!on10Sec) {
              setOn10Sec(true);
            }
          }}
        />
      )}
      <div className={`holder ${on10Min ? "" : "hide"}`}>
        <img className={"slika"} src="/images/tabla_prazna.png" alt="Tabla" />
        <Confetti
          className={"confetti"}
          active={showConffeti}
          config={confettiConfig}
        />
        <h3 className="broj" style={{ opacity: opacity }}>
          {broj}
        </h3>
      </div>
      <div className="glavni">
        {glavni.map((e, i) => (
          <div key={i}>
            <h4 className="red">{i + 1}. Гранд милионер</h4>
            <div className="glavDob">{e.telefon}</div>
          </div>
        ))}
      </div>
      <div className="dnevni">
        <h3>НЕДЕЛНИ ДОБИТНИЦИ</h3>
        <div className="dnevniHolder customScroll">
          {nedeljni.map((e, i) => (
            <div key={i} className="dob">
              <div>
                <p>{e.telefon}</p>
                <p>{e.datum}</p>
              </div>
              <div>
                <p>{e.tip}</p>
                <p className="red">{e.nagrada}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="dnevni">
        <h3>ДНЕВНИ ДОБИТНИЦИ</h3>
        <div className="dnevniHolder customScroll">
          {dnevni.map((e, i) => (
            <div key={i} className="dob">
              <div>
                <p>{e.telefon}</p>
                <p>{e.datum}</p>
              </div>
              <div>
                <p>{e.tip}</p>
                <p className="red">{e.nagrada}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default Dobitnici;
